<template>
  <v-dialog v-model="dialog" width="1000">
    <v-card>
      <v-card-title v-if="selectedForm">Lomake: {{ selectedForm.name }}</v-card-title>

      <v-card-text v-if="selectedFormSubmission">
        Täytetty: {{ formatDate(selectedFormSubmission.item.createdAt) }}</v-card-text
      >

      <v-card-text>
        <div class="submissions" v-for="(value, key, i) in fields" :key="'a' + i">
          <div class="headline-wrapper">
            <strong>{{ key }}:</strong>
          </div>
          <div class="text-wrapper">
            {{ value }}
          </div>
        </div>
      </v-card-text>

      <v-card-text v-if="Object.keys(oldFields).length > 0">
        <h4 class="mb-1 warning--text">Nykyiseltä lomakkeelta poistetut kentät</h4>

        <div class="submissions" v-for="(value, key, i) in oldFields" :key="'a' + i">
          <div class="headline-wrapper">
            <strong>{{ key }}:</strong>
          </div>
          <div class="text-wrapper">
            {{ value }}
          </div>
        </div>
      </v-card-text>

      <v-card-text v-if="images.length > 0">
        <h4>Kuvat</h4>
        <form-submission-images></form-submission-images>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined color="error" @click="dialog = false">Poistu</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";
import mixins from "@/mixins/mixins";
import FormSubmissionImages from "./FormSubmissionImages.vue";
export default {
  mixins: [mixins],

  components: {
    FormSubmissionImages,
  },

  props: {
    value: { type: Boolean, default: false },
  },

  computed: {
    ...mapState("formSubmission", ["selectedFormSubmission", "images"]),
    ...mapState("form", ["selectedForm"]),

    submissionFields() {
      if (this.selectedFormSubmission) {
        return this.selectedFormSubmission.item.fields;
      }
      return null;
    },

    rows() {
      if (this.selectedForm) {
        return this.selectedForm.rows;
      } else {
        return [];
      }
    },

    fields() {
      const fields = {};

      if (this.rows.length > 0 && this.submissionFields) {
        this.rows.forEach((row) => {
          row.cols.forEach((col) => {
            if (col.label && col.label in this.submissionFields) {
              const value = this.formatField(this.submissionFields[col.label], col.label);
              fields[col.label] = value;
            }
          });
        });
        return fields;
      } else {
        return {};
      }
    },
    // These fields type have changed and they are not present anymore in rows or metadata, they are found in oldMetadata
    oldFields() {
      const oldFields = {};

      if (this.submissionFields) {
        for (const key in this.submissionFields) {
          if (!(key in this.fields)) {
            const value = this.formatField(this.submissionFields[key], key);
            oldFields[key] = value;
          }
        }

        return oldFields;
      } else {
        return {};
      }
    },

    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },

  methods: {
    formatField(value, key) {
      if (this.selectedForm) {
        const meta = this.selectedForm.oldMetadata;
        if (key in meta) {
          if (meta[key].type == "boolean") {
            return this.formatBoolean(value);
          }
          if (meta[key].type == "date") {
            return this.formatDate(value);
          }
          return value;
        } else {
          return value;
        }
      }
    },
  },
};
</script>

<style scoped>
.submissions {
  display: flex;
  flex-wrap: wrap;
}

.headline-wrapper {
  width: 250px;
  flex-shrink: 0;
  padding-right: 10px;
}

.text-wrapper {
  flex: 1;
  overflow-wrap: break-word;
}

@media (max-width: 550px) {
  .submissions {
    flex-direction: column;
    align-items: flex-start;
  }

  .headline-wrapper {
    width: 100%;
  }
}
</style>
